import { useCpaEntityQuery } from 'hooks/use-cpa-entity'
import { useThankyouData } from 'hooks/use-thankyou-data'
import { ChatActivate } from '../components/Chat/ChatActivate'
import { Webform } from '../components/Webform/Webform'
import { A2ADataContextProvider } from '../contexts/A2ADataContext'
import { LayoutContextProvider } from '../contexts/LayoutContext'

const ScriviciPage = () => {
  const thankyouData = useThankyouData()
  const chatQuery = useCpaEntityQuery()

  return (
    <A2ADataContextProvider>
      <LayoutContextProvider>
        <ChatActivate chatQuery={chatQuery} />
        <Webform thankyouData={thankyouData} />
      </LayoutContextProvider>
    </A2ADataContextProvider>
  )
}

export default ScriviciPage
